import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { MessageFormContext } from "../NewMessageActionForm";
import { ResponseExpectedToggleButton } from "./ResponseExpectedToggleButton";

const OrganisationMessageSection = ({
  messageRequired = false,
  responseRequired = false,
}) => {
  const {
    organisationResponse,
    organisationError,
    sendMessageOrganisation,
    setSendMessageOrganisation,
    stakeholderContacts,
    setOrganisationError,
  } = useContext(MessageFormContext);

  //const value = formDefaults?.organisationMessage;

  const handleSentMessage = () => {
    setSendMessageOrganisation((prev) => !prev);
  };

  useEffect(() => {
    if (organisationResponse?.message || !sendMessageOrganisation) {
      setOrganisationError(false);
    }
  }, [organisationResponse?.message, sendMessageOrganisation]);

  return (
    <Grid
      xs={6}
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          minHeight: "35px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>
          Send Message to <b>Organisation</b>
        </Typography>
        {!messageRequired && (
          <ToggleButtonGroup
            size="small"
            value={sendMessageOrganisation}
            disabled={messageRequired}
            exclusive
            onChange={messageRequired ? null : handleSentMessage}
            sx={{
              "& .MuiToggleButton-root": {
                py: 0,
                fontSize: "12px",
                textTransform: "none",
                "&.Mui-selected": {
                  backgroundColor: "#007bff",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                },
              },
              "& .MuiToggleButtonGroup-grouped": {
                borderRadius: 1,
                mx: 0,
              },
            }}
          >
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      {(messageRequired || sendMessageOrganisation) && (
        <Select
          size="small"
          value={organisationResponse.toContactId}
          sx={{ fontSize: "12px" }}
          onChange={(e) => organisationResponse.setToContactId(e.target.value)}
        >
          {stakeholderContacts?.["organisation"]?.map((contact) => (
            <MenuItem key={contact.id} value={contact.id}>
              {contact.name}
            </MenuItem>
          ))}
        </Select>
      )}
      {(messageRequired || sendMessageOrganisation) && (
        <>
          <TextField
            error={organisationError}
            helperText={
              organisationError ? (
                `Please write a message ${
                  messageRequired ? "" : "or uncheck send message"
                }`
              ) : organisationResponse.resetToCannedResponse ? (
                <>
                  Form has been updated since editing reponse.
                  <Link
                    underline="hover"
                    onClick={organisationResponse.resetToCannedResponse}
                  >
                    {" "}
                    Click to reset
                  </Link>
                </>
              ) : null
            }
            disabled={!sendMessageOrganisation}
            multiline
            size="small"
            value={organisationResponse.message}
            onChange={(e) => organisationResponse.setMessage(e.target.value)}
            InputProps={{
              sx: {
                height: "100%",
              },
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ResponseExpectedToggleButton
              disabled={responseRequired}
              channel="organisation"
            />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default OrganisationMessageSection;
