import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import _ from 'lodashExtended';
import { flatMap } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DebugButton } from "service/GlobalDebugger";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useTicket } from "ticket/selectors";

const ReattemptVisitCard = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};

  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();
  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
  } = useContext(AppContext);

  const [open, setOpen] = useState(true);

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x
  );

  // Find who inputted the request
  // TODO: Get inputtedById on the request ?
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.inputtedById
  );

  const isSelected = useMemo(() => {
    return (
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  const isInCurrentPath =
    currentTicketId === String(request?.linkedTicketIds[0]);

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(request.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...request.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  const statusMap = {
    awaiting_date: "#FFA600",
    date_received: "#1CAF38",
  };

  return (
    <>
      <Box
        sx={{
          mx: -2,
          "& .MuiPaper-root": {
            borderRadius: "0px",
          },
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
        }}
        key={request.id}
        data-link-card={request.id}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#1E1E1E",
          }}
        >
          <CardContent sx={{ pt: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{}}
            >
              <Box>
                <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}>
                  Re-attempt Visit{" "}
                  {_.isPresent(request?.reattemptDate) ? (
                    <>
                      <span style={{ color: "#19E53B" }}>Confirmed </span>
                      for {formatDate(request?.reattemptDate)}
                    </>
                  ) : (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: statusMap["awaiting_date"],
                      }}
                    >
                      Awaiting Date
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    color: "#5F6369",
                  },
                }}
              >
                {isInCurrentPath ? (
                  <IconButton onClick={() => toggleHighlightedReplies()}>
                    <LinkIcon
                      sx={{
                        color: isSelected ? "#4FC1E9 !important" : "###5F6369",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => toggleHighlightedReplies()}
                    underline="none"
                    sx={{
                      fontSize: "11px",
                      cursor: "pointer",
                      color: "#5F6369",
                      "&:hover": {
                        ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                      },
                    }}
                  >
                    #{request?.linkedTicketIds[0]}
                  </Link>
                )}
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <UpArrowIcon /> : <DownArrowIcon />}
                </IconButton>

                <DebugButton {...request} />
              </Box>
            </Stack>
            <Box
              sx={{
                mx: -2,
                my: "3px",
                borderBottom: "1px solid #5F6369",
              }}
            />
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  { _.isPresent(request?.reattemptDate) ? (
                    <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                      Visit confirmed for{" "}
                      <span style={{ color: "#19E53B" }}>
                        {formatDate(request?.reattemptDate)}
                      </span>
                      <Typography
                        component="span"
                        sx={{ fontSize: "11px", color: "#828B98" }}
                      >
                        {" "}
                        on{" "}
                        <span style={{ color: "#BFBFBF" }}>
                          {formatDate(request?.confirmedAt)}
                        </span>{" "}
                        by{" "}
                        <span style={{ color: "#BFBFBF" }}>
                          {inputtedBy?.nameWithoutEmail || "No Agent"}
                        </span>
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                      Visit confirmed for{" "}
                      <Chip
                        label="Awaiting Date"
                        size="small"
                        sx={{
                          background: "#5F6369",
                          color: "#BFBFBF",
                          fontSize: "11px",
                          fontWeight: "bold",
                        }}
                      />
                    </Typography>
                  )}
                  <Typography
                    color="grey"
                    sx={{ fontSize: "11px", color: "#5F6369" }}
                  >
                    Requested at{" "}
                    <span style={{ color: "#828B98" }}>
                      {formatDate(request?.requestedAt)}
                    </span>{" "}
                    by{" "}
                    <span style={{ color: "#828B98" }}>
                      {inputtedBy?.nameWithoutEmail || "No Agent"}
                    </span>
                  </Typography>
                </Box>
              </Collapse>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ReattemptVisitCard;
