import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton } from "@mui/material";
import TextSmall from "components/TextSmall";
import { flatMap } from "lodash";
import { useState } from "react";
import SpecChangeRequestLog from "service/request-logs/SpecChangeRequestLog";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";
import AdhocVisitReplyTag from "./AdhocVisitReplyTag";
import FailedVisitReplyTag from "./FailedVisitReplyTag";
import VendorFeedbackReplyTag from "./VendorFeedbackReplyTag";
import NoActionRequiredLog from "./NoActionRequiredLog";
import ReattemptDateLog from "./ReattemptDateLog";
import ReattemptDateRequestLog from "./ReattemptDateRequestLog";
import RemediationLog from "./RemediationLog";
import ServiceReinstatementRequestLog from "./ServiceReinstatementRequestLog";
import ServiceResumptionRequestLog from "./ServiceResumptionRequestLog";
import ServiceSuspensionRequestLog from "./ServiceSuspensionRequestLog";
import ServiceTerminationRequestLog from "./ServiceTerminationRequestLog";
import StockDeliveryRequestLog from "./StockDeliveryRequestLog";
import VisitFrequencyChangeRequestLog from "./VisitFrequencyChangeRequestLog";

const replyTagsComponents = {
  AdhocVisitRequest: AdhocVisitReplyTag,
  FailedVisitReport: VendorFeedbackReplyTag,
  SpecChangeRequest: SpecChangeRequestLog,
  VisitFrequencyChangeRequest: VisitFrequencyChangeRequestLog,
  StockDeliveryRequest: StockDeliveryRequestLog,
  ServiceTerminationRequest: ServiceTerminationRequestLog,
  ServiceReinstatementRequest: ServiceReinstatementRequestLog,
  ServiceSuspensionRequest: ServiceSuspensionRequestLog,
  ServiceResumptionRequest: ServiceResumptionRequestLog,
  VendorFeedbackOnVisitFailureRecieve: VendorFeedbackReplyTag,
  ClientFeedbackOnVisitFailureRecieve: null,
  ReattemptVisitRequest: ReattemptDateRequestLog,
  ReattemptDateConfirm: ReattemptDateLog,
  RemediationSent: RemediationLog,
  RemediationResponseRejected: RemediationLog,
  RemediationCancelled: RemediationLog,
  RemediationReplyLogged: RemediationLog,
  "No Action Required": NoActionRequiredLog,
};

const ReplyTag = ({ eventData, url }) => {
  const { stakeholderContacts } = useTicket() || {};
  const allStakeholders = flatMap(stakeholderContacts || [], (x) => x);
  const [openStates, setOpenStates] = useState({});

  const getLogComponent = (eventName) => {
    const componentName = Object.keys(replyTagsComponents).find((name) =>
      eventName.includes(name),
    );
    return componentName ? replyTagsComponents[componentName] : null;
  };

  const toggleOpen = (index) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const isNewComponentStructure = (component) => {
    return (
      typeof component === "object" &&
      component.TagHeader &&
      component.TagContent
    );
  };

  return (
    <>
      {eventData.map((event, index) => {
        const inputtedBy = allStakeholders?.find(
          (x) => x.id === event?.metadata?.inputtedById,
        );
        const SpecificLogComponent = getLogComponent(event.name);

        if (!SpecificLogComponent) return null;

        const isNewStructure = isNewComponentStructure(SpecificLogComponent);

        return (
          <>
            {isNewStructure ? (
              <>
                <Divider
                  variant="middle"
                  sx={{
                    m: 0,
                    mx: -2,
                    borderColor: "#fff",
                    opacity: "0.2",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    px: 1,
                    backgroundColor: openStates[index] ? "#121212" : "inherit",
                    borderBottom: "0px dashed #4B4B4B",
                    borderWidth: openStates[index] ? "1px" : "0",
                  }}
                  onClick={() => toggleOpen(index)}
                >
                  <SpecificLogComponent.TagHeader request={event} />
                  <IconButton disableRipple sx={{ color: "#fff" }}>
                    {openStates[index] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={openStates[index]} timeout={0} unmountOnExit>
                  <Box
                    sx={{
                      m: "6px 0 10px 8px",
                      px: "10px",
                    }}
                  >
                    <SpecificLogComponent.TagContent
                      request={event}
                      url={url}
                    />
                    {inputtedBy && (
                      <Box sx={{ mt: "5px", textAlign: "right" }}>
                        <TextSmall>
                          Inputted by{" "}
                          <strong>{inputtedBy?.nameWithoutEmail}</strong> on{" "}
                          <strong>{formatDate(event?.createdAt)}</strong>
                        </TextSmall>
                      </Box>
                    )}
                  </Box>
                </Collapse>
              </>
            ) : (
              <Box sx={{ px: 1 }}>
                <Divider
                  variant="middle"
                  sx={{
                    m: 0,
                    mx: -2,
                    borderColor: "#fff",
                    opacity: "0.2",
                  }}
                />
                <SpecificLogComponent request={event} url={url} />
              </Box>
            )}
          </>
        );
      })}
    </>
  );
};

export default ReplyTag;
