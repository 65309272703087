import CheckIcon from "@mui/icons-material/Check";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import { useGetLocationRemediationQuery } from "api/services";
import { flatMap, get, last, startCase, camelCase, mapKeys } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BinDot from "service/BinDot";
import { DebugButton } from "service/GlobalDebugger";
import {
  DownArrowIcon,
  RemediationIcon,
  UpArrowIcon,
} from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import {
  getInUseBinGroupsForService,
  getLocationId,
  useTicket,
} from "ticket/selectors";

const deliveryFailureMap = {
  total_failure: "Nothing was delivered",
  partial_failure: "Some was delivered",
  success: "All was delivered",
};

const collectionFailureMap = {
  total_failure: "Nothing was collected",
  partial_failure: "Some was collected",
  success: "All was collected",
};

const statusMap = {
  awaiting_feedback: "#FFA600",
  feedback_received: "#1CAF38",
};

const ReportCard = ({ report }) => {
  const { stakeholderContacts } = useTicket() || {};

  const inUseBinGroups = getInUseBinGroupsForService(report.serviceUuid);

  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();

  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    setRequestedAtDate,
    onOpenRemediation,
    setEventId,
    setRequestType
  } = useContext(AppContext);

  const remediationData = useGetLocationRemediationQuery(locationId, {skip: !locationId}).data?.remediation;
  const remediation = remediationData ? mapKeys(remediationData, (_, k) => camelCase(k)) : null;

  const [open, setOpen] = useState(true);

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  const inputtedBy = allStakeholders?.find(
    (x) => x.id === report?.inputtedById,
  );

  const isInCurrentPath =
    currentTicketId === String(report?.linkedTicketIds[0]);

  const isSelected = useMemo(() => {
    return (
      report?.replyIds.length > 0 &&
      report?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  // Determine the latest feedback and collected bin group IDs
  const { latestFeedback, collectedBinGroupIds, uncollectedBinGroupIds } =
    useMemo(() => {
      const vendorFeedback = last(report.vendorFeedback);
      const clientFeedback = last(report.clientFeedback);

      const latest = vendorFeedback || clientFeedback;

      return {
        latestFeedback: latest,
        collectedBinGroupIds: get(
          latest,
          "visitReport.collectedBinGroupIds",
          report.collectedBinGroupIds,
        ),
        uncollectedBinGroupIds: get(
          latest,
          "visitReport.uncollectedBinGroupIds",
          report.uncollectedBinGroupIds,
        ),
      };
    }, [report]);

  // Update delivery and collection result slugs based on latest feedback
  const deliveryResultSlug = get(
    latestFeedback,
    "visitReport.deliveryResultSlug",
    report.deliveryResultSlug,
  );
  const collectionResultSlug = get(
    latestFeedback,
    "visitReport.collectionResultSlug",
    report.collectionResultSlug,
  );

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(report.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this report is already selected, deselect it
        newHighlights = [];
      } else {
        // If this report is not selected, select it and deselect others
        newHighlights = [...report.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = report?.linkedTicketIds[0];
      const queryParam = `highlight=${report?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  return (
    <>
      <Box
        sx={{
          mx: -2,
          "& .MuiPaper-root": {
            borderRadius: "0px",
          },
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
        }}
        key={report.id}
        data-link-card={report.id}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#1E1E1E",
          }}
        >
          <CardContent sx={{ pt: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}
                >
                  Failed Visit Report on{" "}
                  <span style={{ color: "#B5B6B6" }}>
                    {formatDate(report?.date)}{" "}
                  </span>
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: statusMap[report.status],
                    }}
                  >
                    {startCase(report.status)}
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    color: "#5F6369",
                  },
                }}
              >
                {isInCurrentPath ? (
                  <IconButton onClick={() => toggleHighlightedReplies()}>
                    <LinkIcon
                      sx={{
                        color: isSelected ? "#4FC1E9 !important" : "###5F6369",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => toggleHighlightedReplies()}
                    underline="none"
                    sx={{
                      fontSize: "11px",
                      cursor: "pointer",
                      color: "#5F6369",
                      "&:hover": {
                        ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                      },
                    }}
                  >
                    #{report?.linkedTicketIds[0]}
                  </Link>
                )}
                {(collectedBinGroupIds.length > 0 ||
                  uncollectedBinGroupIds.length > 0) && (
                  <IconButton onClick={() => setOpen(!open)}>
                    {open ? <UpArrowIcon /> : <DownArrowIcon />}
                  </IconButton>
                )}

                {
                  (!remediation || remediation.status == 'accepted' || remediation.status == 'cancelled') && <IconButton
                    onClick={() => {
                      setRequestedAtDate(report?.requestedAt || report?.reportedAt);
                      setEventId(report?.id);
                      setRequestType(report?.type);
                      onOpenRemediation();
                    }}
                  >
                    <RemediationIcon />
                  </IconButton>
                }
                <DebugButton {...report} />
              </Box>
            </Stack>
            <Box
              sx={{
                mx: -2,
                my: "3px",
                borderBottom: "1px solid #5F6369",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "400", color: "#828B98" }}
                >
                  Reported by{" "}
                  {report.reportedByStakeholderId === 1 ||
                  report.reportedByStakeholderId === 2
                    ? "Client"
                    : "Vendor"}
                </Typography>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "400", color: "#828B98" }}
                >
                  {report?.driverTurnUp
                    ? "Driver turn up"
                    : "Driver didn't turn up "}
                </Typography>
              </Box>
              <Typography
                color="grey"
                sx={{ fontSize: "11px", color: "#5F6369" }}
              >
                Reported on{" "}
                <span style={{ color: "#828B98" }}>
                  {formatDate(report?.reportedAt)}
                </span>{" "}
                by{" "}
                <span style={{ color: "#828B98" }}>
                  {inputtedBy?.nameWithoutEmail || "No Agent"}
                </span>
              </Typography>
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit>
              {(collectedBinGroupIds.length > 0 ||
                uncollectedBinGroupIds.length > 0) && (
                <Box
                  sx={{
                    display: "flex",
                    "& > .MuiBox-root": {
                      width: "50%",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#828B98",
                      }}
                    >
                      What Anenta reported as collected & missed
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#BFBFBF",
                      }}
                    >
                      {collectionFailureMap[collectionResultSlug]}
                    </Typography>
                    <TableContainer>
                      <Table
                        size="small"
                        sx={{
                          "& .MuiTableCell-root": {
                            padding: 0,
                            fontSize: "11px",
                            border: "none",
                          },
                          width: "auto",
                          tableLayout: "fixed",
                        }}
                      >
                        <TableBody
                          sx={{
                            "& .MuiTableCell-root": {
                              fontSize: "11px",
                              color: "#BFBFBF",
                            },
                          }}
                        >
                          {inUseBinGroups.map((bg: any) => (
                            <BinGroupRow
                              key={bg.id}
                              binGroup={bg}
                              isCollected={collectedBinGroupIds.includes(bg.id)}
                              isUncollected={uncollectedBinGroupIds.includes(
                                bg.id,
                              )}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#828B98",
                      }}
                    >
                      What Anenta reported as delivered & missed
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "bold",
                        color: "#BFBFBF",
                      }}
                    >
                      {deliveryFailureMap[deliveryResultSlug]}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Collapse>
            <Box
              sx={{
                display: "flex",
                "& > .MuiBox-root": {
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                },
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                  Reason for failure
                </Typography>
                {report?.collectionFailureReason?.name ? (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#BFBFBF",
                    }}
                  >
                    {report.collectionFailureReason.name}
                  </Typography>
                ) : (
                  <Chip
                    label="Not Supplied"
                    size="small"
                    sx={{
                      background: "#5F6369",
                      color: "#BFBFBF",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                  Reason for failure
                </Typography>
                {report?.deliveryFailureReason?.name ? (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: "#BFBFBF",
                    }}
                  >
                    {report.deliveryFailureReason.name}
                  </Typography>
                ) : (
                  <Chip
                    label="Not Supplied"
                    size="small"
                    sx={{
                      background: "#5F6369",
                      color: "#BFBFBF",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ReportCard;

const BinGroupRow = ({ binGroup, isCollected, isUncollected }) => {
  const getIcon = () => {
    if (isCollected) return <CheckIcon />;
    if (isUncollected) return <CloseIcon />;
    return <></>;
  };

  const getIconColor = () => {
    if (isCollected) return "success.main";
    if (isUncollected) return "error.main";
    return "#767676";
  };

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <BinDot binGroup={binGroup} tooltipName={true} tooltipPlace="top" />
          <Typography noWrap>{binGroup.binTypes.join(", ")}</Typography>
        </Box>
      </TableCell>
      <TableCell align="center">
        <IconButton
          size="small"
          sx={{
            color: getIconColor(),
          }}
        >
          {getIcon()}
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
