import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, Divider, IconButton, Typography } from "@mui/material";
import { Contact } from "api/tickets";
import { flatMap, startCase } from "lodash";
import { useState } from "react";
import { formatDate } from "sharedUtils";
import { useTicket } from "ticket/selectors";

const ReattemptDateLog = ({ request }) => {
  const { stakeholderContacts } = useTicket() || {};
  const { reattemptDate, notes } = request.data || {};

  const [open, setOpen] = useState(false);
  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x
  );

  // Find who inputted the request
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" fontWeight="bold">
          {startCase(request.name)} for {formatDate(reattemptDate)}
        </Typography>

        <Box flex="1" />
        <IconButton
          disableRipple
          onClick={() => setOpen(!open)}
          sx={{
            "& .MuiSvgIcon-root": { fontSize: "20px", color: "#fff" },
          }}
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      {open && (
        <Divider
          variant="middle"
          sx={{
            m: 0,
            mx: -2,
            mb: 1,
            borderColor: "#fff",
            opacity: "0.2",
          }}
        />
      )}

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{
          my: 1,
          "& th.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
          "& td.MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          },
        }}
      >
        {notes && (
          <Typography variant="h6" sx={{ my: 1 }}>
            {notes}
          </Typography>
        )}

        <Typography color="grey" sx={{ fontSize: "10px" }}>
          Inputted by {inputtedBy?.nameWithoutEmail} on{" "}
          {formatDate(request?.createdAt)}
        </Typography>
      </Collapse>
    </>
  );
};

export default ReattemptDateLog;
