import { Grid } from "@mui/material";
import OrganisationMessageSection from "./common/OrganisationMessageSection";
import VendorMessageSection from "./common/VendorMessageSection";

interface ServiceActionMessageFormProps {
  formDefaults: {
    vendor: {
      responseRequired: boolean,
        messageRequired: boolean
    },
      organisation: {
        responseRequired: boolean,
          messageRequired: boolean
      }
  }
}

export const ServiceActionMessageForm:FC<ServiceActionMessageFormProps> = ({ formDefaults }) => {
  return (
    <Grid container spacing={2}>
      <OrganisationMessageSection {...formDefaults.organisation} />
      <VendorMessageSection {...formDefaults.vendor} />
    </Grid>
  );
};



