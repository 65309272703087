import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  // typography: {
  //   htmlFontSize: 10,
  // },
  palette: {
    success: {
      main: "#8CC152",
      contrastText: "#fff",
    },
    outlined: {
      main: "#555656",
    },
    white: {
      main: "#fff",
    },
    info: {
      main: "#637381",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
      variants: [
        {
          props: { size: "xsmall" },
          style: {
            height: "20px",
            borderRadius: "5px",
            "& .MuiChip-label": {
              paddingLeft: "6px",
              paddingRight: "6px",
              fontSize: "10px",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontStyle: "normal",
          fontWeight: 600,
          // height: "35px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: "10px 0px",
          "&::before": {
            borderTop: "3px solid rgba(0, 0, 0, 0.12)",
          },
          "&::after": {
            borderTop: "3px solid rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "20px", // Set the default fontSize for icons
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px", // Set the default fontSize for tooltip content
          background: "#000",
        },
        arrow: {
          color: "#000",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        SelectDisplayProps: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontSize: "12px",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: "12px",
        },
      },
    },
  },
});

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    info: true;
  }
}
