import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {
  useFormServiceActionMutation,
  useGetLocationRemediationQuery,
  useGetLocationServiceVendorQuery,
} from "api/services";
import { camelCase, mapKeys } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PdfModal from "service/forms/common/PdfModal";
import { DebugButton } from "service/GlobalDebugger";
import { DownArrowIcon, UpArrowIcon } from "service/utils/Icons";
import { formatDate } from "sharedUtils";
import AppContext from "ticket/AppContext";
import { getLocationId, useTicket, useService } from "ticket/selectors";

const statusMessages = {
  awaiting_response: { text: "Awaiting formal response", color: "#FFA600" },
  cancelled: { text: "Cancelled", color: "red" },
  vendor_replied: { text: "Review required", color: "#FFA600" },
  accepted: {
    text: "response accepted",
    color: "#19E53B",
  },
  rejected: {
    text: "Response Rejected",
    color: "red",
  },
};

const RemediationCard = ({ remediation, isLog = false }) => {
  const ticket = useTicket();
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('')

  const [submitForm] = useFormServiceActionMutation();
  const [serviceUuid, setServiceUuid] = useState("");
  const { data: locationServiceVendor } = useGetLocationServiceVendorQuery(
    {
      locationId: `${ticket?.location?.id}`,
      vendorId: ticket?.vendor?.id,
    },
    { skip: !ticket?.location?.id || !ticket?.vendor?.id },
  );
  const service = useService(serviceUuid)

  useEffect(() => {
    if (!locationServiceVendor) return;
    setServiceUuid(locationServiceVendor[ticket?.vendor?.id].services[0].uuid);
  }, [locationServiceVendor]);

  const statusMessage = statusMessages[remediation?.status];
  const acceptRemediation = () => {
    const acceptUrl = `/admin/service_actions/${serviceUuid}/accept_remediation_response`;
    submitResponse(acceptUrl);
  };

  const rejectRemediation = () => {
    const rejectUrl = `/admin/service_actions/${serviceUuid}/reject_remediation_response`;
    submitResponse(rejectUrl);
  };

  const submitResponse = (url) => {
    submitForm({
      url: url,
      body: {
        tagged_reply_id: remediation.lastTaggedReplyId,
        form: {
          vendor_id: ticket?.vendor?.id,
          ticket_id: ticket?.id,
          location_uuid: ticket?.location?.uuid,
          service_uuid: serviceUuid,
          authority_id: ticket?.location?.authority_id,
        },
      },
    });
  };

  const history = useHistory();
  const location = useLocation();
  const { currentTicketId, setHighlightedIds, highlightedIds } =
    useContext(AppContext);

  const isInCurrentPath =
    currentTicketId === String(remediation?.remediationTicketId);
  const isSelected = useMemo(() => {
    return (
      remediation?.replyIds.length > 0 &&
      remediation?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(remediation.remediationTicketId) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...remediation.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = remediation?.remediationTicketId;
      const queryParam = `highlight=${remediation?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  const pdfUrls = {
    notice: remediation?.noticePdfUrl,
    response: remediation?.replyPdfUrl
  };

  const handleOpenPdf = (pdfType) => () => {
    setOpenModal(true);
    setPdfUrl(pdfUrls[pdfType]);
  };

  return remediation ? (
    <>
      <Box
        sx={{
          mx: isLog ? 0 : undefined,
          "& .MuiPaper-root": {
            borderRadius: "0px",
          },
        }}
        key={remediation.id}
        data-link-card={remediation.id}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#1E1E1E",
            "& .MuiCardContent-root:last-child": {
              pb: 1,
            },
          }}
        >
          <CardContent
            sx={{
              pt: isLog ? 0 : 1,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                ...(isLog
                  ? {
                      borderTop: "1px solid rgba(255, 255, 255, 0.2)",
                      borderBottom: open
                        ? "1px solid rgba(255, 255, 255, 0.2)"
                        : "none",
                      margin: "0px -16px",
                      padding: "0px 16px",
                    }
                  : {}),
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}>
                  {remediation.status === "rejected" ? "Latest Remediation " : "Remediation "}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: statusMessage.color || "#FFA600",
                    }}
                  >
                    {statusMessage.text}
                  </Typography>
                  {remediation.status === "rejected" && (
                    <>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#BFBFBF",
                        }}
                      >
                        {" "}
                        By {service?.authorityFullname},{" "}
                      </Typography>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "#FFA600",
                        }}
                      >
                         Awaiting Formal Response
                      </Typography>
                    </>
                  )}
                  <span style={{ color: "#828B98" }}> for</span> <strong>{remediation.serviceActionReason}</strong>
                  {remediation.status !== "awaiting_response" &&
                    remediation.status !== "canceled" &&
                    remediation.status !== "rejected" &&
                    ` by ${service?.authorityFullname}`}
                </Typography>
              </Box>
              <Box
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    color: "#5F6369",
                  },
                }}
              >
                {isInCurrentPath ? (
                  <IconButton onClick={() => toggleHighlightedReplies()}>
                    <LinkIcon
                      sx={{
                        color: isSelected ? "#4FC1E9 !important" : "###5F6369",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => toggleHighlightedReplies()}
                    underline="none"
                    sx={{
                      fontSize: "11px",
                      cursor: "pointer",
                      color: "#5F6369",
                      "&:hover": {
                        ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                      },
                    }}
                  >
                    #{remediation?.remediationTicketId}
                  </Link>
                )}
                {remediation?.replyPdfUrl && (
                  <Link
                    onClick={handleOpenPdf('response')}
                    underline="none"
                    sx={{ cursor: "pointer", mr: 1 }}
                  >
                    Open Response
                  </Link>
                )}
                <Link
                  onClick={handleOpenPdf('notice')}
                  underline="none"
                  sx={{ cursor: "pointer" }}
                >
                  Open Notice
                </Link>
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <UpArrowIcon /> : <DownArrowIcon />}
                </IconButton>

                <DebugButton {...remediation} />
              </Box>
            </Stack>
            <Box
              sx={{
                mx: -2,
                my: "3px",
                borderBottom: "1px solid #5F6369",
              }}
            />

            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: isLog ? 1 : 0,
                }}
              >
                <Typography
                  sx={{ fontSize: "11px", fontWeight: "400", color: "#828B98" }}
                >
                  Date of contract failure{" "}
                  <span style={{ color: "#BFBFBF" }}>
                    {formatDate(remediation.dateOfContractFailure)}
                  </span>
                </Typography>
                <Typography
                  color="grey"
                  sx={{ fontSize: "11px", color: "#5F6369" }}
                >
                  Requested on{" "}
                  <span style={{ color: "#828B98" }}>
                    {formatDate(remediation?.requestedOn)}
                  </span>{" "}
                  by{" "}
                  <span style={{ color: "#828B98" }}>
                    {remediation.inputedByFullName || "No Agent"}
                  </span>
                </Typography>
              </Box>
              <Typography
                sx={{ fontSize: "11px", color: "#828B98", mb: isLog ? 1 : 0 }}
              >
                {remediation.message}
              </Typography>
              {remediation?.status == "vendor_replied" && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ textTransform: "none" }}
                    onClick={() => acceptRemediation()}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ textTransform: "none" }}
                    onClick={() => rejectRemediation()}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </Collapse>
          </CardContent>
        </Card>
      </Box>
      <PdfModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pdfUrl={pdfUrl}
      />
    </>
  ) : (
    <></>
  );
};

export default RemediationCard;
