import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { DownArrowIcon, UpArrowIcon } from "service/utils/Icons";
import { DebugButton } from "service/GlobalDebugger";
import { formatDate } from "sharedUtils";
import PdfModal from "service/forms/common/PdfModal";
import { useState } from "react";
import { Contact } from "api/tickets";
import { useTicket } from "ticket/selectors";
import { flatMap, mapKeys, camelCase } from "lodash";
import { useGetLocationRemediationQuery } from "api/services";

const statusMessages = {
  RemediationSent: { text: "sent", color: "#FFA600" },
  RemediationCancelled: { text: "Cancelled", color: "red" },
  RemediationReplyLogged: { text: "response received", color: "#19E53B" },
  RemediationResponseAccepted: {
    text: "The remediation response is logged as a Valid PDF",
    color: "#19E53B",
  },
  RemediationResponseRejected: {
    text: "Remediation review required for Adhoc Visit by Kim (contract manager)",
    color: "#FFA600",
  },
};

const RemediationLog = ({ request }) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(true);
  const statusMessage = statusMessages[request.name];

  const { stakeholderContacts, location } = useTicket();
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.metadata?.inputtedById,
  );

  const remediationData = useGetLocationRemediationQuery(location?.id, {
    skip: !location?.id,
  }).data?.remediation;
  const remediation = remediationData
    ? mapKeys(remediationData, (_, k) => camelCase(k))
    : null;

  const pdfUrl = request.name == "RemediationSent" ? request.data.notice?.noticePdfUrl : request.data.reply?.attachmentUrl;

  return (
    <>
      <Box
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0px",
          },
        }}
        key={request.id}
        data-link-card={request.id}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#1E1E1E",
            "& .MuiCardContent-root:last-child": {
              pb: 1,
            },
          }}
        >
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{
                borderTop: "1px solid rgba(255, 255, 255, 0.2)",
                borderBottom: open
                  ? "1px solid rgba(255, 255, 255, 0.2)"
                  : "none",
                margin: "0px -16px",
                padding: "0px 0px",
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}
                >
                  Remediation
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      color: statusMessage.color,
                    }}
                  >
                    {` ${statusMessage.text}`}
                  </Typography>
                  <span style={{ color: "#828B98" }}> for</span>{" "}
                  <strong>{request.data.serviceActionReason}</strong>
                </Typography>
              </Box>
              <Box
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    color: "#5F6369",
                  },
                }}
              >
                {remediation?.status !== 'cancelled' && (
                  <Link
                    onClick={() => setOpenModal(true)}
                    underline="none"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Open Notice
                  </Link>
                )}
                  {request.name == "RemediationSent" ? (
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <UpArrowIcon /> : <DownArrowIcon />}
                </IconButton>) : <></> }

                <DebugButton {...request} />
              </Box>
            </Stack>
            <Box
              sx={{
                mx: -2,
                my: "3px",
                borderBottom: "1px solid #5F6369",
              }}
            />

          {request.name == "RemediationSent" ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "11px",
                      fontWeight: "400",
                      color: "#828B98",
                    }}
                  >
                    Date of contract failure{" "}
                    <span style={{ color: "#BFBFBF" }}>
                      {formatDate(request.data.notice.dateOfContractFailure)}
                    </span>
                  </Typography>
                  <Typography
                    color="grey"
                    sx={{ fontSize: "11px", color: "#5F6369" }}
                  >
                    Requested on{" "}
                    <span style={{ color: "#828B98" }}>
                      {formatDate(request.data.notice.serviceActionRequestedAt)}
                    </span>{" "}
                    by{" "}
                    <span style={{ color: "#828B98" }}>
                      {inputtedBy?.nameWithoutEmail || "No Agent"}
                    </span>
                  </Typography>
                </Box>
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "#828B98",
                  mb: 0,
                }}
              >
                  {request.data.notice.message}
              </Typography>
            </Collapse>
          ) : <></>}
          </CardContent>
        </Card>
      </Box>
      <PdfModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        pdfUrl={pdfUrl}
      />
    </>
  );
};

export default RemediationLog;
