import { useEffect, useState } from "react";
import { DebugButton } from "service/GlobalDebugger";
import PropTypes from "prop-types";
import "react-infinite-calendar/styles.css";
import "./styles.scss";
import Debugger from "./Debugger";
import { CheckBoxWithLabel, ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import dayjs from "dayjs";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";

const ConfirmTerminationForm = ({ form, context, errors, onSetForm }) => {
  const {
    nextVisits,
    serviceName,
    finalCollectionRequired,
    finalCollectionRequiredBefore,
    requestedAt,
  } = context;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(dayjs());

  const visitDays = nextVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  useEffect(() => {
    if (finalCollectionRequired && finalCollectionRequiredBefore) {
      const formattedDate = dayjs(finalCollectionRequiredBefore).format("DD/MM/YYYY");
      setValue(dayjs(finalCollectionRequiredBefore));
      onSetForm({ finalCollectionDate: formattedDate });
    } else {
      const requestedDate = dayjs(requestedAt).format("DD/MM/YYYY");
      setValue(dayjs(requestedAt));
      onSetForm({ noVisitsExpectedAfter: requestedDate });
    }
  }, [finalCollectionRequired, finalCollectionRequiredBefore]);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    if (finalCollectionRequired) {
      onSetForm({ finalCollectionDate: newValue.format("DD/MM/YYYY") });
    } else {
      onSetForm({ noVisitsExpectedAfter: newValue.format("DD/MM/YYYY") });
    }
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Confirm Termination
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>
      <div
        className="mt-3"
        style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        {finalCollectionRequired ? (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to terminate service with final collection
              before {formatDate(finalCollectionRequiredBefore)}
            </Box>
            <label>Final Collection on</label>
            <ServiceCalendarDatePicker
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              maxDate={dayjs(finalCollectionRequiredBefore)}
              value={value}
              onChange={handleDateChange}
            />
          </div>
        ) : (
          <div>
            <Box sx={{ marginTop: 1 }}>
              Vendor was requested to terminate service without final collection
              and effective from: {formatDate(requestedAt)}
            </Box>
            <label>No Visits Expected After</label>
            <ServiceCalendarDatePicker
              visitDays={visitDays}
              open={open}
              setOpen={setOpen}
              value={value}
              onChange={handleDateChange}
            />
          </div>
        )}

        <NotesSection
          form={form}
          onSetForm={onSetForm}
          context={context}
          contactPlaceholder="Who confirmed the termination?"
          contactLabel="Confirmed by"
          dateLabel="Confirmed on"
          dateValue={form?.confirmedAt}
          onChange={(newValue) =>
            onSetForm({
              confirmedAt: newValue.format("DD/MM/YYYY"),
            })
          }
        />
      </div>

      <DebugButton {...form} />

      <ModelErrors errors={errors} />
    </div>
  );
};

ConfirmTerminationForm.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.string,
    finalCollectionDate: PropTypes.string,
    noVisitsExpectedAfter: PropTypes.string,
  }),
  errors: PropTypes.object,
  context: PropTypes.shape({
    serviceName: PropTypes.string.isRequired,
    nextVisits: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
  }),
  onSetForm: PropTypes.func.isRequired,
};

export default ConfirmTerminationForm;
