import { Box, TextField } from "@mui/material";

const NotesSection = ({ form, onSetForm }) => {
  const { notes } = form || {};
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        my: 1,
        mx: -2,
        p: "8px 16px",
        background: "#E2E2EA",

        "& .MuiTypography-root": {
          fontSize: "12px",
          fontWeight: "bold",
          minWidth: "85px",
        },
      }}
    >
      <Box
        flex="1"
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "8.5px 14px",
          },
        }}
      >
        <TextField
          placeholder="Notes"
          multiline
          fullWidth
          maxRows={2}
          value={notes}
          onChange={(e) =>
            onSetForm({
              notes: e.target.value,
            })
          }
          InputProps={{
            sx: {
              p: 0,
              fontSize: "12px",
              background: "#fff",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default NotesSection;
