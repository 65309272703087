import CheckIcon from "@mui/icons-material/Check";
import ReplyIcon from "@mui/icons-material/Reply";
import TagIcon from "@mui/icons-material/Tag";
import { Box, Card, CardActions, Tooltip, Typography } from "@mui/material";
import { TicketReply } from "api/tickets";
import { useGetAllStaffQuery } from "api/users";
import TextSmall from "components/TextSmall";
import { startCase, upperCase } from "lodash";
import { useContext } from "react";
import { DebugButton } from "service/GlobalDebugger";
import ReplyTag from "service/request-logs/ReplyTag";
import { formatTime, updateQueryStringParameter } from "sharedUtils";
import AppContext from "ticket/AppContext";
import ActionMenuButton from "../actions-menu/ActionMenuButton";
import ReplyCardContent from "./ReplyCardContent";
import ReplyCardHeader from "./ReplyCardHeader";
import { styles } from "./ReplyCardStyles";

const getChannelColors = (channel, stakeholderName) => {
  if (channel === "organisation" && stakeholderName === "Organisation") {
    return { shadow: "#37BC9B", background: "#264E50" };
  } else if (channel === "vendor" && stakeholderName === "Vendor") {
    return { shadow: "#CF2D42", background: "#2E070C" };
  } else {
    return { shadow: "#DD9907", background: "#2A2518" };
  }
};

const ReplyStatus = ({ reply }) => {
  const { data: usersById, isLoading } = useGetAllStaffQuery();

  const { processedEvents, status, processedByDefault } = reply;

  if (processedByDefault) {
    return null;
  } else {
    return (
      status !== "new_message" && (
        <>
          <Tooltip
            title={
              !isLoading && (
                <>
                  {processedEvents.map(
                    ({ name, inputtedById, createdAt, id }) => (
                      <div
                        style={{ whiteSpace: "nowrap" }}
                        key={id || "legacy"}
                      >
                        - {name} by {usersById[inputtedById].fullName} on{" "}
                        {formatTime(createdAt)}
                      </div>
                    ),
                  )}
                </>
              )
            }
            placement="top"
            arrow
          >
            <Box
              sx={{ display: "flex", alignItems: "center", gap: "4px", mr: 1 }}
            >
              <CheckIcon sx={{ color: "#37BC9B" }} />
              <TextSmall color="#37BC9B">{startCase(status)}</TextSmall>
            </Box>
          </Tooltip>
        </>
      )
    );
  }
};

const ReplyCard = ({
  reply,
  toggleOpenForm,
  setOpenFormUrl,
  selected,
}: {
  reply: TicketReply;
  toggleOpenForm;
  setOpenFormUrl;
  selected;
}) => {
  const { setShowTaggingForm } = useContext(AppContext);
  const { channel, tagActions, responseActions } = reply;

  const isNewMessage = reply?.status === "new_message";
  const { shadow, background } = getChannelColors(
    channel,
    reply?.stakeholderName,
  );

  const responseFormOnClickHandler = (_e: any, option: any) => {
    toggleOpenForm();
    setOpenFormUrl(
      updateQueryStringParameter(option.path, "respond_to_reply_id", reply.id),
    );
  };

  const taggingFormOnClickHandler = (_e: any, option: any) => {
    toggleOpenForm();
    setShowTaggingForm(true);
    setOpenFormUrl(
      updateQueryStringParameter(option.path, "tagged_reply_id", reply.id),
    );
  };

  return (
    <Card
      sx={{
        backgroundColor: "#23272D",
        ...styles.card(reply),
        ...(isNewMessage && {
          background: background,
          borderWidth: "3px",
        }),
        ...(selected && {
          borderWidth: "3px",
          backgroundColor: "#1E1E1E", // TODO: this is not changing on select, the border is.
        }),
        display: "flex",
        flexDirection: "column",
      }}
      key={reply.id}
    >
      <ReplyCardHeader reply={reply} />
      <ReplyCardContent reply={reply} />
      {channel !== "agent" && (
        <CardActions
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            mt: "auto",
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            "& > :not(style) ~ :not(style)": {
              marginLeft: "0px", // Override the default 8px margin
            },
          }}
        >
          {responseActions.length > 0 && (
            <ActionMenuButton
              onClickHandler={responseFormOnClickHandler}
              icon={<ReplyIcon />}
              serviceActions={responseActions}
            />
          )}

          {tagActions.length > 0 && (
            <ActionMenuButton
              onClickHandler={taggingFormOnClickHandler}
              icon={<TagIcon />}
              serviceActions={tagActions}
            />
          )}

          <Box flex="1" />
          {reply?.status === "new_message" && (
            <Typography fontSize="11px" fontWeight="bold" sx={{ mr: 1 }}>
              {upperCase(reply.status)}
            </Typography>
          )}

          <ReplyStatus reply={reply} />

          <Box sx={{ mr: 1 }}>
            <DebugButton {...reply} />
          </Box>
        </CardActions>
      )}
      {reply?.event_data.length > 0 && (
        <ReplyTag eventData={reply.event_data} url={reply.url} />
      )}
    </Card>
  );
};

export default ReplyCard;
