import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Grid, Stack, Typography } from "@mui/material";
import YesNoSelect from "components/YesNoSelect";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useState } from "react";
import "react-infinite-calendar/styles.css";
import { ModelErrors } from "sharedComponents";
import { formatDate } from "sharedUtils";
import BinReportTable from "./common/BinReportTable";
import NotesSection from "./common/NotesSection";
import ServiceCalendarDatePicker from "./common/ServiceCalendarDatePicker";
import "./styles.scss";

const ClientReportVisitFailureForm = ({ form, context, errors, onSetForm }) => {
  const [open, setOpen] = useState(false);

  const {
    driverTurnUp,
    requestReattempt,
  } = form;

  const {
    serviceName,
    inUseBinGroups,
    lastVisits,
    nextVisits,
  } = context;

  const visitDays = lastVisits?.map((visit) => {
    return { ...visit, date: dayjs(visit.date) };
  });

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Visit Failure From Client
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          / {serviceName}
        </Typography>
      </Stack>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        sx={{
          my: 1,
          "& .MuiGrid-item:first-of-type": {
            pl: 1,
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
            "& .MuiTextField-root": { width: "fit-content" },
          }}
        >
          <Box>
            <Typography>Failure Date</Typography>
            <ServiceCalendarDatePicker
              open={open}
              visitDays={visitDays}
              setOpen={setOpen}
              maxDate={dayjs(new Date())}
              onChange={(newValue) =>
                onSetForm({
                  failureDate: newValue.format("DD/MM/YYYY"),
                })
              }
            />
          </Box>
          <Box>
            <YesNoSelect
              label="Did the driver turn up?"
              onChange={(driverTurnUp) =>
                onSetForm({
                  driverTurnUp,
                })
              }
              value={driverTurnUp}
            />
          </Box>
        </Grid>
        {driverTurnUp && (
          <>
            <Grid item xs>
              {!isEmpty(inUseBinGroups) && (
                <BinReportTable
                  inUseBinGroups={inUseBinGroups}
                  onSetForm={onSetForm}
                  mode="collection"
                  isClient
                />
              )}
            </Grid>
            <Grid item xs>
              <BinReportTable
                inUseBinGroups={[]}
                onSetForm={onSetForm}
                mode="delivery"
                isClient
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          "& .MuiTypography-root": {
            fontSize: "12px",
            fontWeight: "bold",
          },
          "& .MuiTextField-root": { width: "fit-content" },
        }}
      >
        <YesNoSelect
          label="Do you want to request re-attempt date?"
          value={requestReattempt}
          onChange={(requestReattempt) =>
            onSetForm({
              requestReattempt,
            })
          }
        />
        {requestReattempt === false && nextVisits[0]?.date && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "#5C5F62",
              display: "flex",
              alignItems: "center",
              fontWeight: "500 !important",
            }}
          >
            <ReportProblemIcon sx={{ color: "#CF2D42" }} />
            The Next Visit would be a routine visit on{" "}
            {formatDate(nextVisits[0]?.date) || "waiting vendor response"}
          </Typography>
        )}
      </Box>

      <NotesSection form={form} onSetForm={onSetForm} />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default ClientReportVisitFailureForm;
