import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { startCase } from "lodash";
import { useContext } from "react";
import { MessageFormContext } from "../NewMessageActionForm";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const ResponseExpectedToggleButton = ({ disabled, channel }) => {
  // Get both organisationResponse & vendorResponse
  const { [channel + "Response"]: channelResponse } =
    useContext(MessageFormContext);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        minHeight: "35px",
      }}
    >
      {disabled ? (
        <Typography sx={{ fontSize: "12px", display: "flex" }}>
          <ReportProblemIcon color="error" />
          <span>
            This message requires a response from the{" "}
            <b>{startCase(channel)}</b>
          </span>
        </Typography>
      ) : (
        <>
          <Typography sx={{ fontSize: "12px" }}>
            (TODO) Does this message requries a response from the{" "}
            <b>{startCase(channel)}</b> ?
          </Typography>
          <ToggleButtonGroup
            size="small"
            value={channelResponse.responseExpected}
            exclusive
            onChange={() =>
              channelResponse.setResponseExpected(
                !channelResponse.responseExpected,
              )
            }
            sx={{
              "& .MuiToggleButton-root": {
                py: 0,
                fontSize: "12px",
                textTransform: "none",
                "&.Mui-selected": {
                  backgroundColor: "#007bff",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                },
              },
              "& .MuiToggleButtonGroup-grouped": {
                borderRadius: 1,
                mx: 0,
              },
            }}
          >
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
        </>
      )}
    </Box>
  );
};
