import { Box, Grid, Skeleton } from "@mui/material";
import { useGetLocationServiceVendorsQuery } from "api/services";
import { map, sortBy, toPairs } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { RouterDebugger } from "service/GlobalDebugger";
import LocationServicesVendorBox from "service/LocationServicesVendorBox";
import {
  StyledTab,
  StyledTabs,
  TabPanel,
} from "service/report-request-box/MainTabs";
import { useDrawer } from "service/ticket/channel-footer/useDrawer";
import TicketsList from "service/ticket/TicketsList";
import TicketTab from "service/ticket/TicketTab";
import VisitActivityTab from "service/ticket/VisitActivityTab";
import VisitDetails from "service/VisitDetails";
import { formatDate, updateQueryStringParameter } from "sharedUtils";
import { useNavigation, useQuery } from "./app/hooks";
import { useTypedSelector } from "./app/store";
import AppContext from "./AppContext";
import {
  getLocationId,
  getTicketById,
  useGlobalLoadingState,
} from "./selectors";

const App = () => {
  const defaultTicket = useTypedSelector((state) => state.ticketId);
  const [currentTicketId, setCurrentTicketId] = useState(defaultTicket);

  const query = useQuery();
  const ticket = getTicketById(currentTicketId);
  const locationId = getLocationId();
  const history = useHistory();
  const location = useLocation();
  const { onSelectDate, onSelectSideTab } = useNavigation();

  const vendorFromTicket = useMemo(() => ticket?.vendor?.id, [ticket]);

  const detailsDate = query?.get("detailsDate");
  const sideTab = query?.get("sideTab") || "services";

  // Load initial data!!!
  // `/admin/locations/${locationId}/services`
  const { data: locationServiceVendors } =
    useGetLocationServiceVendorsQuery(locationId);

  const {
    isDrawerOpen,
    closeDrawer,
    activeChannel,
    setIsDrawerOpen,
    setActiveChannel,
    toggleDrawerAndSetChannel,
  } = useDrawer();

  const tabMap = {
    services: 0,
    otherTickets: 1,
    visitDetails: 2,
  };

  const [openFormUrl, setOpenFormUrl] = useState<string>("");
  const [showFooterButtons, setShowFooterButtons] = useState(true);
  const [appTab, setAppTab] = useState(0);
  const [selectedContainer, setSelectedContainer] = useState(new Set());
  const [showOverview, setShowOverview] = useState(false);
  const [formType, setFormType] = useState("all");
  const [cardTab, setCardTab] = useState("request");
  const [showTaggingForm, setShowTaggingForm] = useState(false);
  const [highlightedIds, setHighlightedIds] = useState([]);
  const [openRemediation, setOpenRemediation] = useState(false);
  const [requestedAtDate, setRequestedAtDate] = useState("");
  const [eventId, setEventId] = useState("");
  const [requestType, setRequestType] = useState("");
  const [shouldScroll, setShouldScroll] = useState(false);

  const globalLoadingState = useGlobalLoadingState();
  const isGloballyLoading = globalLoadingState;

  const sortedLocationServiceVendors = sortBy(
    toPairs(locationServiceVendors),
    ([vendorId]) => vendorId !== vendorFromTicket?.toString()
  );

  const onOpenRemediation = () => {
    setShowFooterButtons(false);
    setOpenRemediation(true);
  };
  const onCloseRemediation = () => {
    setShowFooterButtons(true);
    setOpenRemediation(false);
  };

  const onSelectAction = (_x, { path }) => {
    setIsDrawerOpen(true);
    setActiveChannel(null);
    setShowFooterButtons(false);
    setOpenFormUrl(updateQueryStringParameter(path, "ticket_id", ticket.id));
  };

  const handlePrimaryTab = (_event, newValue) => {
    setAppTab(newValue);
    const currentSearch = location.search;

    if (newValue === 0) {
      history.replace({
        pathname: `/tickets/${currentTicketId}`,
        search: currentSearch,
      });
    } else if (newValue === 1) {
      history.replace({
        pathname: `/visit_activity/`,
        search: currentSearch,
      });
    }
  };

  useEffect(() => {
    const { pathname, search } = location;
    const ticketMatch = pathname.match(/\/tickets\/(\d+)/);
    let newPathname = pathname;

    if (ticketMatch) {
      const newTicketId = ticketMatch[1];
      setCurrentTicketId(newTicketId);
      newPathname = `/tickets/${newTicketId}`;
    } else if (pathname === "/" && defaultTicket) {
      newPathname = `/tickets/${defaultTicket}`;
    } else if (detailsDate || sideTab) {
      if (pathname.includes("/visit_activity/")) {
        setAppTab(1);
      }
    }

    if (newPathname !== pathname || search !== location.search) {
      history.replace({ pathname: newPathname, search });
    }
  }, [history, defaultTicket, location, detailsDate, sideTab]);

  return (
    <AppContext.Provider
      value={{
        onSelectAction,
        selectedContainer,
        setSelectedContainer,
        formType,
        setFormType,
        cardTab,
        setCardTab,
        showTaggingForm,
        setShowTaggingForm,
        highlightedIds,
        setHighlightedIds,
        currentTicketId,
        isDrawerOpen,
        closeDrawer,
        activeChannel,
        setIsDrawerOpen,
        setActiveChannel,
        toggleDrawerAndSetChannel,
        onSelectDate: onSelectDate,
        // TODO: Remove when we have remediation model
        openRemediation,
        onOpenRemediation,
        onCloseRemediation,
        requestedAtDate,
        setRequestedAtDate,
        eventId,
        setEventId,
        requestType,
        setRequestType,
        shouldScroll,
        setShouldScroll,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          "& .MuiGrid-item:first-of-type": {
            pl: 3,
          },
        }}
      >
        <Grid
          item
          xs={8}
          sx={{
            pt: 0,
            position: "relative",
          }}
        >
          <RouterDebugger />
          <StyledTabs
            value={appTab}
            onChange={handlePrimaryTab}
            sx={{
              height: "fit-content",
              position: "sticky",
              top: showOverview ? 0 : 80,
              paddingTop: "0 !important",
              zIndex: 1,
            }}
          >
            <StyledTab label="Ticket" />
            <StyledTab label="Visit Activity" />
          </StyledTabs>
          <Switch>
            <Route path="/tickets/:ticketId?">
              <TicketTab
                isGloballyLoading={isGloballyLoading}
                appTab={appTab}
                showOverview={showOverview}
                setShowOverview={setShowOverview}
                openFormUrl={openFormUrl}
                setOpenFormUrl={setOpenFormUrl}
                setShowFooterButtons={setShowFooterButtons}
                onSelectAction={onSelectAction}
                showFooterButtons={showFooterButtons}
              />
            </Route>

            <Route path="/visit_activity/">
              <VisitActivityTab
                appTab={appTab}
                onSelectDate={onSelectDate}
                onSelectSideTab={onSelectSideTab}
                selectedDate={detailsDate}
              />
            </Route>
          </Switch>
        </Grid>
        <Grid
          item
          xs={4}
          id="service-box"
          sx={{
            height: "fit-content",
            position: "sticky",
            top: 80,
            marginTop: 2,
            paddingTop: "0 !important",
          }}
        >
          <>
            <StyledTabs
              value={tabMap[sideTab]}
              onChange={(_e, newValue) => {
                const selectedTab = Object.keys(tabMap).find(
                  (key) => tabMap[key] === newValue
                );
                onSelectSideTab(selectedTab);
              }}
            >
              <StyledTab label="Services" />

              <StyledTab
                label="Other Tickets"
                onClick={() => onSelectSideTab("otherTickets")}
              />

              <StyledTab
                label={formatDate(detailsDate)}
                sx={{ display: detailsDate ? "block" : "none" }}
              />
            </StyledTabs>
            <TabPanel value={tabMap[sideTab]} index={tabMap.services}>
              {isGloballyLoading && (
                <Box width="100%">
                  <Skeleton variant="rectangular" height={500} />
                </Box>
              )}
              <Box
                sx={{ visibility: isGloballyLoading ? "hidden" : "visible" }}
              >
                {sortedLocationServiceVendors &&
                  map(
                    sortedLocationServiceVendors,
                    ([vendorId, locationServiceVendorData], key) => (
                      <Fragment key={key}>
                        <LocationServicesVendorBox
                          key={vendorId}
                          {...locationServiceVendorData}
                          initialOpenState={vendorId == ticket?.vendor?.id}
                        />
                        <Box
                          sx={{
                            height: "8px",
                            background: "#262b31",
                          }}
                        />
                      </Fragment>
                    )
                  )}
              </Box>
            </TabPanel>
            <TabPanel value={tabMap[sideTab]} index={tabMap.otherTickets}>
              <TicketsList />
            </TabPanel>
            <TabPanel value={tabMap[sideTab]} index={tabMap.visitDetails}>
              <VisitDetails
                onBack={() => undefined}
                locationServiceVendors={locationServiceVendors}
                date={detailsDate}
              />
            </TabPanel>
          </>
        </Grid>
      </Grid>
    </AppContext.Provider>
  );
};

export default App;
