import { Box, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import RequestsCards from "./report-request-box/RequestsCards";
import FailedVisitsCollapsible from "./service-box/FailedVisitsCollapsible";
import OutstandingRequestsCollapsible from "./service-box/OutstandingRequestsCollapsible";
import ServiceBox from "./ServiceBox";
import VendorHeader from "./VendorHeader";
import { useGetAllLocationRemediationsQuery } from "api/services";
import { getLocationId } from "ticket/selectors";
import { mapKeys, camelCase } from "lodash";

const LocationServicesVendorBox = ({
  vendor,
  services,
  calendar,
  initialOpenState,
}) => {
  const [openServiceMap, setOpenServiceMap] = useState({});

  useEffect(() => {
    const newOpenServiceMap = {};
    services?.forEach((service) => {
      if (
        service?.status === "terminated" ||
        service?.status === "account_closed"
      ) {
        newOpenServiceMap[service.id] = false;
      } else {
        newOpenServiceMap[service.id] = initialOpenState;
      }
    });
    setOpenServiceMap(newOpenServiceMap);
  }, [services, initialOpenState]);

  const handleOpenServiceChange = (serviceId, isOpen) => {
    setOpenServiceMap((prevState) => ({
      ...prevState,
      [serviceId]: isOpen,
    }));
  };

  const locationId = getLocationId();
  const remediationsData = useGetAllLocationRemediationsQuery(locationId, {skip: !locationId}).data?.remediations || [];
  const remediations = remediationsData.map(remediation => mapKeys(remediation, (_, key) => camelCase(key)));

  return (
    <>
      <Card
        sx={{
          "& .MuiCardContent-root:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <CardContent
          sx={{
            m: 2,
            my: 0,
            p: 0,
            pb: 1,
          }}
        >
          <VendorHeader vendor={vendor} calendar={calendar} />
          {services?.map((service, key) => (
            <Box key={key}>
              <ServiceBox
                key={service.id}
                service={service}
                openService={openServiceMap[service.id]}
                setOpenServiceMap={(isOpen) =>
                  handleOpenServiceChange(service.id, isOpen)
                }
              />
              {openServiceMap[service.id] && (
                <FailedVisitsCollapsible service={service} />
              )}

              <RequestsCards requests={service?.requests} remediations={remediations} />

              {services.length > 1 && (
                <Box
                  sx={{ height: "8px", background: "#262b31", mx: -2 }}
                ></Box>
              )}

              {openServiceMap[service.id] && (
                <OutstandingRequestsCollapsible service={service} />
              )}
            </Box>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default LocationServicesVendorBox;
