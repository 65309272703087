import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useEffect } from "react";
import { MessageFormContext } from "../NewMessageActionForm";
import { ResponseExpectedToggleButton } from "./ResponseExpectedToggleButton";

const VendorMessageSection = ({
  messageRequired = false,
  responseRequired = false,
}) => {
  const {
    vendorResponse,
    vendorError,
    sendMessageVendor,
    setSendMessageVendor,
    stakeholderContacts,
    setVendorError,
  } = useContext(MessageFormContext);

  //const value = formDefaults?.vendorMessage;

  const handleSentMessage = () => {
    setSendMessageVendor((prev) => !prev);
  };

  useEffect(() => {
    if (vendorResponse?.message || !sendMessageVendor) {
      setVendorError(false);
    }
  }, [vendorResponse?.message, sendMessageVendor]);

  return (
    <Grid
      xs={6}
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          minHeight: "35px",
        }}
      >
        <Typography sx={{ fontSize: "12px" }}>
          Send Message to <b>Vendor</b>
        </Typography>
        {!messageRequired && (
          <ToggleButtonGroup
            size="small"
            value={sendMessageVendor}
            disabled={messageRequired}
            exclusive
            onChange={messageRequired ? null : handleSentMessage}
            sx={{
              "& .MuiToggleButton-root": {
                py: 0,
                fontSize: "12px",
                textTransform: "none",
                "&.Mui-selected": {
                  backgroundColor: "#007bff",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                },
              },
              "& .MuiToggleButtonGroup-grouped": {
                borderRadius: 1,
                mx: 0,
              },
            }}
          >
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>

      {(messageRequired || sendMessageVendor) && (
        <Select
          size="small"
          value={vendorResponse.toContactId}
          sx={{ fontSize: "12px" }}
          onChange={(e) => vendorResponse.setToContactId(e.target.value)}
        >
          {stakeholderContacts?.["vendor"]?.map((contact) => (
            <MenuItem key={contact.id} value={contact.id}>
              {contact.name}
            </MenuItem>
          ))}
        </Select>
      )}

      {(messageRequired || sendMessageVendor) && (
        <>
          <TextField
            size="small"
            multiline
            error={vendorError}
            helperText={
              vendorError ? (
                `Please write a message ${
                  messageRequired ? "" : "or uncheck send message"
                }`
              ) : vendorResponse.resetToCannedResponse ? (
                <>
                  Form has been updated since editing reponse.
                  <Link
                    underline="hover"
                    onClick={vendorResponse.resetToCannedResponse}
                  >
                    {" "}
                    Click to reset
                  </Link>
                </>
              ) : null
            }
            value={vendorResponse.message}
            onChange={(e) => vendorResponse.setMessage(e.target.value)}
            InputProps={{
              sx: {
                height: "100%",
              },
            }}
            sx={{ flex: 1 }}
          />
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ResponseExpectedToggleButton
              disabled={responseRequired}
              channel="vendor"
            />
          </Box>
        </>
      )}
    </Grid>
  );
};

export default VendorMessageSection;
