import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import _, { first, map } from "lodash";
import { useState } from "react";
import { useBinFetch } from "sharedUtils";
import ServiceBoxContext from "ticket/ServiceBoxContext";
import BinDot from "./BinDot";
import { DebugButton } from "./GlobalDebugger";
import "./service-box.scss";
import { StatusRow } from "./service-box/StatusRow";
import VendorAccountLink from "./service-box/VendorAccountLink";
import { VisitFrequencyRow } from "./service-box/VisitFrequencyRow";
import VlcSection from "./service-box/VlcSection";
import { Specification } from "./Specification";
import { StreamIcon } from "./utils/Icons";

const ServiceBox = ({
  service,
  openService,
  setOpenServiceMap: setOpenService,
}) => {
  const currentVendorLocationAccount: any = first(service?.serviceMappings);

  const specifications = service?.specifications;
  const billingHistoryUnitAverages = service?.billingHistoryUnitAverages;

  const [open, setOpen] = useState(false);

  const allCodes = _([
    _.map(specifications, "serviceCode"),
    _.map(billingHistoryUnitAverages, "code"),
  ])
    .flatten()
    .uniq()
    .value();

  const { binGroups } = useBinFetch(allCodes);

  const availableBinGroups = map(binGroups, (b, key) => {
    return <BinDot key={key} binGroup={b} tooltipName={true} tooltipPlace="top" />;
  });

  const noSpecs = Object.keys(specifications).length === 0;

  return (
    <ServiceBoxContext.Provider
      value={{
        currentVendorLocationAccount,
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: openService ? 0 : 2,
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <DebugButton {...service} top="-16px" />
        <Box>
          <Box position="relative">
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {service?.title}
              {service?.streamUrl && (
                <IconButton
                  onClick={() => window.open(service?.streamUrl, "_blank")}
                  sx={{ fontSize: "10px" }}
                >
                  <StreamIcon sx={{ fontSize: "15px" }} />
                </IconButton>
              )}
            </Typography>
            <IconButton
              disableRipple
              onClick={() => setOpenService(!openService)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "35px" },
                position: "absolute",
                top: "-16px",
                right: "0px",
              }}
            >
              {openService ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
            color: "#000",
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <StatusRow service={service} />
          <Divider orientation="vertical" flexItem sx={{ m: 0 }} />
          <Box>
            <VisitFrequencyRow service={service} />
          </Box>
        </Box>
        {!openService && (
          <VendorAccountLink
            vendorAccount={currentVendorLocationAccount?.vendorAccount}
            vendorAccountClosedMonthName={service?.vendorAccountClosedMonthName}
          />
        )}
        <Collapse in={openService} timeout="auto" unmountOnExit>
          <VlcSection service={service} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #F1F1F5",
              mx: -2,
            }}
          >
            <Box
              sx={{
                mx: 2,
                display: "flex",
                gap: 1,
                alignItems: "center",
                py: noSpecs ? 1 : 0,
              }}
            >
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: "12px",
                  color: noSpecs ? "#828b98" : "inherit",
                }}
              >
                {noSpecs && "Undefined"} Specs
              </Typography>

              {!open && allCodes.length > 0 && <>{availableBinGroups}</>}
            </Box>

            {Object.keys(specifications).length > 0 && (
              <IconButton
                disableRipple
                onClick={() => setOpen(!open)}
                sx={{
                  position: "relative",
                  right: "16px",
                  py: "4px",
                  "& .MuiSvgIcon-root": { fontSize: "35px" },
                }}
              >
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
          </Box>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              m: -2,
              my: 0,
            }}
          >
            <Specification {...service} />
          </Collapse>
        </Collapse>
      </Box>
    </ServiceBoxContext.Provider>
  );
};

export default ServiceBox;
