import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import LinkIcon from "@mui/icons-material/Link";
import TagIcon from "@mui/icons-material/Tag";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Contact } from "api/tickets";
import { useGetLocationRemediationQuery } from "api/services";
import { flatMap, map, size, startCase, toLower, mapKeys, camelCase } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BinDot from "service/BinDot";
import { DebugButton } from "service/GlobalDebugger";
import {
  DownArrowIcon,
  RemediationIcon,
  UpArrowIcon,
} from "service/utils/Icons";
import { formatDate, useBinFetch } from "sharedUtils";
import { useTypedSelector } from "ticket/app/store";
import AppContext from "ticket/AppContext";
import { getLocationId, getTicketId, useTicket } from "ticket/selectors";
import {
  getRequestType,
  getTypographyLabel,
} from "./AdhocVisitRequestCardDetails";

const AdhocVisitRequestCard = ({ request }) => {
  const { binTypes, binGroups } = useBinFetch();
  const { stakeholderContacts } = useTicket() || {};

  const { deliveryUnits } = request || {};
  const history = useHistory();
  const location = useLocation();
  const locationId = getLocationId();
  const {
    currentTicketId,
    highlightedIds,
    setHighlightedIds,
    onOpenRemediation,
    setRequestedAtDate,
    setEventId,
    setRequestType
  } = useContext(AppContext);

  const [open, setOpen] = useState(true);
  const clearAll = request?.clearAll;
  const collectionUnits = clearAll ? [] : request?.collectionUnits;

  const isCollect = size(collectionUnits) > 0;
  const isDelivery = size(deliveryUnits) > 0;
  const isCollectAndDelivery = isCollect && isDelivery;

  const remediationData = useGetLocationRemediationQuery(locationId, {skip: !locationId}).data?.remediation;
  const remediation = remediationData ? mapKeys(remediationData, (_, k) => camelCase(k)) : null;

  const requestType = getRequestType({
    clearAll,
    isCollectAndDelivery,
    isCollect,
    isDelivery,
  });
  const headers = useMemo(() => {
    switch (requestType) {
      case "clearAllAndDeliver":
        return ["", "Deliver Additional", "Action"];
      case "collect":
        return ["", "Collect", "Action"];
      case "collectAndDeliver":
        return ["", "Collect", "Deliver Additional", "Action"];
      case "deliver":
        return ["", "Deliver Additional", "Action"];
      default:
        return ["", "Collect", "Deliver", "Action"];
    }
  }, [requestType]);

  const label = getTypographyLabel(requestType);

  // Get all stakeholders
  const allStakeholders: Contact[] = flatMap(
    stakeholderContacts || [],
    (x) => x,
  );

  // Find who inputted the request
  // TODO: Get inputtedById on the request ?
  const inputtedBy = allStakeholders?.find(
    (x) => x.id === request?.inputtedById,
  );

  const isSelected = useMemo(() => {
    return (
      request?.replyIds.length > 0 &&
      request?.replyIds?.every((id) => highlightedIds?.includes(String(id)))
    );
  }, [highlightedIds]);

  const isInCurrentPath =
    currentTicketId === String(request?.linkedTicketIds[0]);

  const collectAndDeliver = collectionUnits?.reduce((acc, unit) => {
    acc[unit?.serviceCode] = { ...unit };
    return acc;
  }, {});

  deliveryUnits?.forEach((deliveryUnit) => {
    if (collectAndDeliver[deliveryUnit?.serviceCode]) {
      collectAndDeliver[deliveryUnit?.serviceCode].deliveryQty =
        deliveryUnit?.quantity;
    } else {
      collectAndDeliver[deliveryUnit?.serviceCode] = {
        serviceCode: deliveryUnit?.serviceCode,
        deliveryQty: deliveryUnit?.quantity,
      };
    }
  });

  const newRows = map(
    collectAndDeliver,
    ({
      serviceCode,
      quantity: collectionQuantity,
      visitActionId,
      deliveryQty: deliveryQuantity,
    }) => {
      const binType = binTypes[serviceCode];
      const binGroup = binGroups[binType?.binGroupId];

      return (
        <TableRow key={serviceCode}>
          <TableCell
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <BinDot
              binGroup={binGroup}
              tooltipName={true}
              tooltipPlace="top"
              {...binType}
            />{" "}
            <Typography>{binType?.name}</Typography>
          </TableCell>

          {["collect", "collectAndDeliver"].includes(requestType) && (
            <TableCell>
              {collectionQuantity === "all" ? "All" : collectionQuantity}
            </TableCell>
          )}

          {["clearAllAndDeliver", "collectAndDeliver", "deliver"].includes(
            requestType,
          ) && <TableCell>{deliveryQuantity || 0}</TableCell>}

          <TableCell
            sx={{
              color: "#5F6369 !important",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {visitActionId ? <span>{startCase(visitActionId)}</span> : null}

            <span>{serviceCode}</span>
          </TableCell>
        </TableRow>
      );
    },
  );

  const toggleHighlightedReplies = () => {
    const searchParams = new URLSearchParams(location.search);

    if (String(request.linkedTicketIds[0]) === currentTicketId) {
      // We're on the same ticket
      let newHighlights = [];

      if (isSelected) {
        // If this request is already selected, deselect it
        newHighlights = [];
      } else {
        // If this request is not selected, select it and deselect others
        newHighlights = [...request.replyIds];
      }

      // Update search params
      searchParams.delete("highlight");
      if (newHighlights.length > 0) {
        newHighlights.forEach((id) => searchParams.append("highlight", id));
      }

      setHighlightedIds(newHighlights);

      // Update the URL and push to history
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    } else {
      // We are not on the same ticket
      const ticketId = request?.linkedTicketIds[0];
      const queryParam = `highlight=${request?.replyIds.join("&highlight=")}`;
      const newTabUrl = `/admin/locations/${locationId}/app#/tickets/${ticketId}?${queryParam}`;
      window.open(newTabUrl, "_blank");
    }
  };

  return (
    <>
      <Box
        sx={{
          mx: -2,
          "& .MuiPaper-root": {
            borderRadius: "0px",
          },
          "& .MuiCardContent-root:last-child": {
            pb: 1,
          },
        }}
        key={request.id}
        data-link-card={request.id}
      >
        <Card
          variant="outlined"
          sx={{
            background: "#1E1E1E",
          }}
        >
          <CardContent sx={{ pt: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Box>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "700", color: "#BFBFBF" }}
                >
                  Adhoc Visit Request
                  {!request.date && !request.cancelledAt && (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        color: "#FFA600",
                      }}
                    >
                      {" "}
                      Awaiting Date
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    color: "#5F6369",
                  },
                }}
              >
                {isInCurrentPath ? (
                  <IconButton onClick={() => toggleHighlightedReplies()}>
                    <LinkIcon
                      sx={{
                        color: isSelected ? "#4FC1E9 !important" : "###5F6369",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Link
                    onClick={() => toggleHighlightedReplies()}
                    underline="none"
                    sx={{
                      fontSize: "11px",
                      cursor: "pointer",
                      color: "#5F6369",
                      "&:hover": {
                        ...(isInCurrentPath ? { color: "#5F6369" } : {}),
                      },
                    }}
                  >
                    #{request?.linkedTicketIds[0]}
                  </Link>
                )}
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? <UpArrowIcon /> : <DownArrowIcon />}
                </IconButton>

                {
                  (!remediation || remediation.status == 'accepted' || remediation.status == 'cancelled') && <IconButton
                    onClick={() => {
                      setRequestedAtDate(request?.requestedAt);
                      setEventId(request?.id);
                      setRequestType(request?.type);
                      onOpenRemediation();
                    }}
                  >
                    <RemediationIcon />
                  </IconButton>
                }
                <DebugButton {...request} />
              </Box>
            </Stack>

            <Box
              sx={{
                mx: -2,
                my: "3px",
                borderBottom: "1px solid #5F6369",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontSize: "13px", fontWeight: "400", color: "#BFBFBF" }}
              >
                {label}
              </Typography>
              <Typography
                color="grey"
                sx={{ fontSize: "11px", color: "#5F6369" }}
              >
                Requested on{" "}
                <span style={{ color: "#828B98" }}>
                  {formatDate(request?.requestedAt)}
                </span>{" "}
                by{" "}
                <span style={{ color: "#828B98" }}>
                  {inputtedBy?.nameWithoutEmail || "No Agent"}
                </span>
              </Typography>
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit>
              {newRows.length > 0 && (
                <AdhocCardTable rows={newRows} headers={headers} />
              )}

              {request?.date ? (
                <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                  Visit confirmed for{" "}
                  <span style={{ color: "#19E53B" }}>
                    {formatDate(request?.date)}
                  </span>
                  <Typography
                    component="span"
                    sx={{ fontSize: "11px", color: "#828B98" }}
                  >
                    {" "}
                    on{" "}
                    <span style={{ color: "#BFBFBF" }}>
                      {formatDate(request?.confirmedAt)}
                    </span>{" "}
                    by{" "}
                    <span style={{ color: "#BFBFBF" }}>
                      {inputtedBy?.nameWithoutEmail || "No Agent"}
                    </span>
                  </Typography>
                </Typography>
              ) : request?.cancelledAt ? (
                <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                  Visit cancelled on{" "}
                  <span style={{ color: "red" }}>
                    {formatDate(request?.cancelledAt)}
                  </span>{" "}
                  <Typography component="span" sx={{ fontSize: "11px" }}>
                    by{" "}
                    <span style={{ color: "#BFBFBF" }}>
                      {inputtedBy?.nameWithoutEmail || "No Agent"}
                    </span>
                  </Typography>
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "13px", color: "#828B98" }}>
                  Visit confirmed for{" "}
                  <Chip
                    label="Awaiting Date"
                    size="small"
                    component="span"
                    sx={{
                      background: "#5F6369",
                      color: "#BFBFBF",
                      fontSize: "11px",
                      fontWeight: "bold",
                    }}
                  />
                </Typography>
              )}
            </Collapse>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export const AdhocCardTable = ({ rows, headers }) => {
  return (
    <TableContainer>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            border: "none",
            padding: 0,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell sx={{ color: "#5F6369", fontSize: "11px" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "11px",
              color: "#BFBFBF",
            },
          }}
        >
          {rows}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdhocVisitRequestCard;

export const ActionButtons = ({ actions, isActive, request, linkSelected }) => {
  const ticketId = useTypedSelector(getTicketId);
  const { onSelectAction, setSelectedContainer } = useContext(AppContext);
  const scrollToTagInCompositeId = () => {
    const specificId = request.id;
    setSelectedContainer(specificId);

    const element = document.getElementById(specificId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const potentialTargets = document.querySelectorAll("[id]");
      const targetsArray = Array.from(potentialTargets); // Convert NodeList to Array
      for (let el of targetsArray) {
        if (el.id.includes(specificId)) {
          el.scrollIntoView({ behavior: "smooth", block: "center" });
          break;
        }
      }
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isActive && (
        <>
          {actions?.map((action) => (
            <Tooltip
              arrow
              placement="top"
              title={startCase(action.value)}
              key={action.value}
            >
              <IconButton
                aria-label={action.label}
                onClick={() => onSelectAction(null, { path: action.path })}
                sx={{ py: 0 }}
              >
                {toLower(action.label) === "confirm" ? (
                  <CheckOutlinedIcon sx={{ color: "#AAB2BD" }} />
                ) : (
                  <HighlightOffOutlinedIcon sx={{ color: "#AAB2BD" }} />
                )}
              </IconButton>
            </Tooltip>
          ))}
        </>
      )}
      {ticketId === request?.linkedTicketIds[0] ? (
        <Tooltip arrow placement="top" title="Go to reply">
          <IconButton
            onClick={scrollToTagInCompositeId}
            sx={{
              ...(linkSelected && {
                border: "1px solid #6c8c89",
              }),
            }}
          >
            <LinkIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip arrow placement="top" title="Go to ticket">
          <IconButton
            onClick={() =>
              window.open(
                `/admin/tickets/${request?.linkedTicketIds[0]}/app`,
                "_blank",
              )
            }
          >
            <TagIcon
              sx={{
                color: "#AAB2BD",
                ...(linkSelected && {
                  fill: "#6c8c89",
                }),
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
