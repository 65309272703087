import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Alert, Box, Collapse, ListItemButton } from "@mui/material";
import { groupBy, map, orderBy } from "lodash";
import { useState } from "react";
import { AdhocVisitRequest, AdhocVisitRequests } from "service/ticket/types";
import { getTicketId } from "ticket/selectors";
import AdhocVisitRequestCard from "./AdhocVisitRequestCard";
import { useTypedSelector } from "ticket/app/store";
interface AdhocVisitRequestProps {
  adhocVisitRequests: AdhocVisitRequests;
}
const AdhocVisitRequestTab: React.FC<AdhocVisitRequestProps> = ({
  adhocVisitRequests,
}) => {
  const ticketId = useTypedSelector(getTicketId);
  const [openStates, setOpenStates] = useState(() => {
    let initialStates = {};
    if (adhocVisitRequests) {
      initialStates = adhocVisitRequests.reduce((acc, request) => {
        const isTicketId = request.linkedTicketIds[0] === Number(ticketId);

        acc[request.id] = false;
        acc[`group-${request.linkedTicketIds[0]}`] = isTicketId;
        return acc;
      }, {});
    }
    return initialStates;
  });

  const handleClick = (id: string) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  const groupedRequests = groupBy(
    orderBy(adhocVisitRequests, ["requestedAt"], ["desc"]),
    (request) => {
      return request?.linkedTicketIds[0];
    }
  );

  const renderGroup = (requests: AdhocVisitRequest[], currentTicketId) => (
    <Box key={currentTicketId}>
      <ListItemButton
        onClick={() => handleClick(`group-${currentTicketId}`)}
        sx={{ fontSize: "12px", fontWeight: "bold", pb: 1, color: "#fff" }}
      >
        {currentTicketId === ticketId
          ? "Requests on this Ticket"
          : `Ticket ID: ${currentTicketId}`}
        {openStates[`group-${currentTicketId}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItemButton>
      <Collapse
        in={openStates[`group-${currentTicketId}`]}
        timeout="auto"
        unmountOnExit
      >
        {requests.map((request) => (
          <AdhocVisitRequestCard
            key={request.id}
            request={request}
            isOpen={openStates[request.id]}
            toggleOpen={() => handleClick(request.id)}
          />
        ))}
      </Collapse>
    </Box>
  );

  return (
    <>
      {Object.values(adhocVisitRequests)?.length === 0 && (
        <Alert severity="warning" sx={{ p: 2, fontSize: "14px" }}>
          No requests to show
        </Alert>
      )}

      {/* Render the group with the specific ticketId first if it exists */}
      {groupedRequests[ticketId] &&
        renderGroup(groupedRequests[ticketId], ticketId)}

      {/* Render other groups */}
      {map(groupedRequests, (requests, currentTicketId) => {
        if (currentTicketId !== ticketId.toString()) {
          return renderGroup(requests, currentTicketId);
        }
      })}
    </>
  );
};

export default AdhocVisitRequestTab;
