import "./styles.scss";
import { DebugButton } from "service/GlobalDebugger";
import Debugger from "./Debugger";
import { ModelErrors } from "sharedComponents";
import { Box, Stack, Typography } from "@mui/material";
import NotesSection from "./common/NotesSection";
import { formatDate } from "sharedUtils";

const CancelTerminationForm = ({ form, context, errors, onSetForm }) => {
  const { serviceName, requestedAt } = context;

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          Cancel Termination Request
        </Typography>
        <Typography color="grey" variant="h6" sx={{ fontWeight: "bold" }}>
          {serviceName}
        </Typography>
      </Stack>
      <Box sx={{ marginTop: 1 }}>Vendor was requested on{" "}{formatDate(requestedAt)}{" "}to terminate service</Box>
      <NotesSection
        form={form}
        onSetForm={onSetForm}
        context={context}
        contactLabel="Canceled By"
        contactPlaceholder="Who requested the cancellation?"
        dateLabel="Cancelled on"
        dateValue={form?.cancelledAt}
        onChange={(newValue) =>
          onSetForm({
            cancelledAt: newValue.format("DD/MM/YYYY"),
          })
        }
      />
      <DebugButton {...form} />
      <ModelErrors errors={errors} />
    </div>
  );
};

export default CancelTerminationForm;
